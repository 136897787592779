import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { FaBars } from 'react-icons/fa';
import { connect } from 'react-redux';
import './style.scss';
import { navItem, navAdminItem } from '../../utility/menu';
import CryptoJS from 'crypto-js';
import { hashKey } from '../../constants/Constants';
import {translate} from '../../lang';

import session from "../../services/session";
let navItems = navItem;
let navAdminItems = navAdminItem;

const sortAsc = (arr, field) => {
    return arr.sort((a, b) => {
      if (a[field] > b[field]) { return 1; }
      if (b[field] > a[field]) { return -1; }
      return 0;
    })
  }
class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems,
            showmenu: false,
            height: (window.innerHeight - 95),
        };
        this.menuRef = React.createRef();
        this.translateFunctionName = this.translateFunctionName.bind(this);   
    }

    componentWillMount() {
        let routeList = session.get("prev", "cookie")
        
        if (this.props.isService) {
            let filteredRouteList = routeList.filter(el => el.functionName !== "EQUIPMENT ALERTS" && el.functionName !== "SP EQUIPMENT ALERTS");
            let dashBoarditems = [{ functionName: "Dashboard", uuid: "/service/dashboard", menu: true }];
            let eqAlertItems = [{ functionName: "EQUIPMENT ALERTS", uuid: "/service/equipment-alerts", menu: true }];
           
            filteredRouteList= eqAlertItems.concat(filteredRouteList) 
            filteredRouteList = sortAsc(filteredRouteList,'functionName')

            filteredRouteList = dashBoarditems.concat(filteredRouteList);
            this.setState({ navItems: filteredRouteList });
        } else {
           let byteFunctionList = CryptoJS.AES.decrypt(localStorage.getItem('FUNCTION_LIST').toString(), hashKey);
           let functionList = JSON.parse(byteFunctionList.toString(CryptoJS.enc.Utf8));
            

            let navItemslist = navItems.filter(el => {
                let arg = false;
                functionList.forEach(fl => {
                    if (fl.functionName == el.functionName) {
                        arg = true;
                    }
                })
                return arg;
            }) 

           this.setState({ navItems: navItemslist });
        }
    }
    translateFunctionName(arg){
        var result = arg.toUpperCase().replace(/\s/g, "_");
      return  translate(result);
    }

    /*handleClick(e) {
        if (this.state.showmenu === true) {
            this.setState({ showmenu: false });
        } else {
            this.setState({ showmenu: true });
        }
    }*/

    handleClick = () => {
        this.setState((prevState) => ({
            showmenu: !prevState.showmenu,
        }), () => {
            if (this.state.showmenu) {
                this.menuRef.current.focus();  // Set focus to the menu when opened
            }
        });
    }

    handleBlur = (event) => {
        if (this.menuRef.current && !this.menuRef.current.contains(event.relatedTarget)) {
            // If the next focused element is outside the menu, close the menu
            this.setState({ showmenu: false });
        }
    }

    componentWillReceiveProps(nextprops) {
        if ((nextprops.clicked !== this.props.clicked) && nextprops.clicked === false && this.state.showmenu === false) {
            this.setState({ showmenu: true })
        } else {
            this.setState({ showmenu: nextprops.clicked });
        }

        /* if(this.props.isAdmin){
            this.setState({ navItems: nextprops.menu || [] });
        } */
    }

    updateDimensions() {
        let height = (window.innerHeight - 87);
        if (window.innerWidth > 767) {
            height = height - 42;
        }
        this.setState({ height });
    }

    render() {
        let isOwner = this.props.profile.ownerFlag ? translate('MY_MEMBERS') : translate('TEAM_MEMBERS');
        let myMember_uuid = this.props.profile.ownerFlag ? "/customer/members": "/customer/teamMembers";
        let adminclass = '', { navItems } = this.state;
        //const fromUrl = this.props.location.pathname;
        if (this.props.isService) {

            adminclass = 'sidebar hidebar';
            if (this.state.showmenu) {
                adminclass = 'sidebar';
            }
            if (!navItems.length) {
                navItems = session.get("prev", "cookie");
            }
        } else {
            adminclass = 'sidebar';
        }

        return (
            <div
                ref={this.menuRef}
                className={adminclass}
                tabIndex="-1"  // Makes the div focusable
                onBlur={this.handleBlur}  // Handle blur (focus out) event
            >
            <div className="navbaralingnment">
                <div style={{ height: this.state.height }} className={(this.state.showmenu) ? `${adminclass}` : `${adminclass} xs-hidden`}>

                    {(!this.props.isService && <div className="menubtnicon xs-hidden" onClick={(e) => this.handleClick(e)}><FaBars className="navicon" /></div>)}

                    <div className="fixed-icon">
                        {navItems && navItems.constructor === Array && navItems.map((item, index) => {
                            let activelink = (window.location.pathname === item.uuid) ? ' navactive' : '';
                            let icon = (window.location.pathname === item.uuid) ? item.icon1 : item.icon;
                            let textAdj = (item.functionName.length > 14) ? `dashboardmenu-text${activelink}` : activelink;
                            let SideBarClass = (this.state.showmenu) ? '' : 'hidebar';
                            let navsecText = this.props.isService ? 'navsec-text-service' : 'navsec-text';
                            if (item.functionName === "My Members") {

                                return (
                                    <div className="navsec" key={index}>
                                        {(!this.props.isService) ?
                                            <div className="menuwrapper"><Link to={{ 
                                                pathname : myMember_uuid,
                                                state: {prevPath: this.props.location.pathname}
                                            }}>{icon}</Link></div>
                                            :
                                            ''
                                        }
                                        <Link to={{pathname: myMember_uuid, state: {prevPath: this.props.location.pathname}}}><div onClick={(e) => this.handleClick(e)} className={`${navsecText} ${textAdj} ${SideBarClass}`}>{isOwner}</div></Link>
                                    </div>
                                );
                            }
                            else {
                                return (
                                    <div className="navsec" key={index}>
                                        {(!this.props.isService) ?
                                            <div className="menuwrapper"><Link to={{pathname: item.uuid,state: {prevPath: this.props.location.pathname}}}>{icon}</Link>
                                            </div>
                                            :
                                            ''
                                        }


                                        {item.menu && <Link to={{ pathname:item.uuid,state: {prevPath: this.props.location.pathname}}}><div onClick={(e) => this.handleClick(e)} className={`${navsecText} ${textAdj} ${SideBarClass}`}>{this.props.isService ? item.functionName : this.translateFunctionName(item.functionName)}</div></Link>}

                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
            </div>
        );
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        this.updateDimensions.bind(this);
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile || undefined,
        menu: state.app.functions || [],
        permissions: state.app.permissions
    };
}
export default connect(mapStateToProps, null)(withRouter(SideBar));
