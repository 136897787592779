import http from "../services/http";
import { __api_getAlerts,__api_getSysMentainInfo,__api_getScheduleSysAlert,__api_getHeaderNotificationCount,__api_userActivity } from "./endpoints";


export const getAlerts = data => {
    return http.Request("GET", __api_getAlerts,data);
}

export const getScheduleSysAlert = data => {
    return http.Request("GET", __api_getScheduleSysAlert,data);
}

export const getHeaderNotificationCount = data => {
    return http.Request("GET", __api_getHeaderNotificationCount,data);
}

//UserActivity Tracker API

export const UserActivity = (data) => {
    return http.Request("POST", __api_userActivity , data);
}








			