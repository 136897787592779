import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import moment from "moment";
import { getEquipmentServiceHistory } from "../../../actions/ServiceHistory";


/** Importing Sub Components */
import SearchEquipmentComponent from "../daily-work/elements/SearchEquipmentComponent";
import EquipmentServiceDetail from "./EquipmentServiceDetail";
import ServiceHistoryDetail from "./ServiceHistoryDetail";
import { withRouter } from 'react-router-dom';
import { UserActivity } from "../../../api/header.js";

/** importings actions */

import { setToastr } from "../../../actions/Application";

/** Importing Sub Components */


class ServiceHistoryComponent extends Component {

    constructor(props) {
        super(props);

        /** Setting State */
        this.state = {
            selectedEquipment: {},
            fromPage:'',
            errorAPI: false,
        };
        /** Function Binding */
        this.getSelectedEquipmentAttr = this.getSelectedEquipmentAttr.bind(this);
        this.getEquipmentServiceHistory = this.getEquipmentServiceHistory.bind(this);
    }
    async getSelectedEquipmentAttr(selectedEquipment = undefined) {
        if (selectedEquipment) {
            await this.setState({
                selectedEquipment: this.getSelectedEquipmentDetails(selectedEquipment),
            });


            this.getEquipmentServiceHistory();
        }
    }
    async getEquipmentServiceHistory(page = 1) {
        let { selectedEquipment } = this.state;
        if (!selectedEquipment || !selectedEquipment.machineSaleId) return false;
        try {
            this.props.getEquipmentServiceHistory({selectedEquipment,page});
        } catch (error) {
            this.props.setToastr({ toastr: { message: "This operation can't be performed. Please try again later.", type: "error" } });
        }
    }

    getSelectedEquipmentDetails(selectedEquipment) {
        /** if no selection, don't allow */
        if (!selectedEquipment.value) return false;

        let { equipments } = this.props;

        /** search equipment from list based on model and plate */
        return equipments.filter(equipment => {
            return selectedEquipment.searchBy === 'model' ? (equipment[selectedEquipment.searchBy] === selectedEquipment.value.split("/")[0] && equipment["machineNum"] === selectedEquipment.value.split("/")[1]) : (equipment[selectedEquipment.searchBy] === selectedEquipment.value);
        })[0] || undefined;
    }
    componentWillUnmount() {
        this.setState({ selectedEquipment: undefined });
    }

    componentWillReceiveProps(newProps) {
        if (this.props.apiError !== newProps.apiError) {
            if (newProps.apiError !== false) {
                this.setState({
                    errorAPI: true,
                    showLoader: false
                })
                if (this.props.profile.userActivityFlag == true) {
                    const { location } = this.props;
                    const toUrl = location.pathname;
                    const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
                    this.trackUserActivity('Failed',(`Failed navigation from ${fromUrl} to ${toUrl}`));
                }
            }
        }
    }

    componentDidMount() {
		if(this.props.profile.userActivityFlag == true){
			const {location} = this.props;
			const toUrl = location.pathname; 
			const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
			this.trackUserActivity('Success',(`Successfully navigated from ${fromUrl} to ${toUrl}`));
		}	
	}

    trackUserActivity(pageActivity,remarks) {
        const {location} = this.props;
        const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
        const toUrlPath = location.pathname.split('/customer/')[1] || '';
        let fromUrlPath = fromUrl ? fromUrl.split('/customer/')[1]:'';
        const pathNames = {
            "manage-daily-page": "Manage Daily Work",
            "equipment": "My Equipments",
            "location-information": "Location Information",
            "alert-setting": "Alert Setting",
            "reports": "Manage Reports",
            "service-history": "Equipment Service History",
            "area-measurement": "Area Measurement",
            "members" : "My Members",
            "member_management": "Member Management",
            "my-account": "My Account"
            // Add other mappings here as needed
        };
        const convertToTitleCase = (str) => {
            if(!str){
                return '';
            }
            
            return str
                .replace(/-/g, ' ') // Replace hyphens with spaces
                .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
        };

        let defaultFromPage = fromUrl === '/customer' ? 'Login' : '';
        let defaultToPage = location.pathname === '/customer' ? 'Login' : '';
        let fromUrlName = pathNames[fromUrlPath] || convertToTitleCase(fromUrlPath) || defaultFromPage;
        let toUrlName = pathNames[toUrlPath] || convertToTitleCase(toUrlPath) || defaultToPage;

        let userActivityData = {
            userID: this.props.profile.id,
            userName: this.props.profile.firstName,
            sourceType: 'web',
            fromPage: fromUrlName,
            toPage: toUrlName,
            pageActivity: pageActivity,
            remarks: remarks
        }
        UserActivity(userActivityData)
            .then(result => {
                if (result.statusCode == 200) {
                    this.setState({fromPage:fromUrl});
                }
            })
            .catch(error => {
                console.error('Error in user activity API', error);
            })
    }

    render() {
        let { equipments, translate } = this.props;
        let { selectedEquipment } = this.state;

        return (
            <Fragment>

                {/* Search Equipment Form Component */}
                <SearchEquipmentComponent translate={translate} equipments={equipments} onEquipmentSelect={this.getSelectedEquipmentAttr} />

                {/* Equipment Component */}
                <EquipmentServiceDetail translate={translate} equipment={selectedEquipment} />
                {/*  Service History Detail */}

                {(Object.keys(selectedEquipment).length > 0) && <ServiceHistoryDetail translate={translate} selectedEquipment={selectedEquipment} getEquipmentServiceHistory={this.getEquipmentServiceHistory } />}
            </Fragment>
        );
    }
}




const mapDispatchToProps = {
    getEquipmentServiceHistory
}

const mapStateToProps = state => {
    return({
        equipments: (state.serviceHistory && state.serviceHistory.data && state.serviceHistory.data.SET_EQUIPMENTS_SERVICE_DATA) ? state.serviceHistory.data.SET_EQUIPMENTS_SERVICE_DATA : [],
        profile: state.profile,
        apiError : state.equipment.FETCH_DATA_FAIL || false,
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceHistoryComponent));