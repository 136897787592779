import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Row, Col, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import MemberCard from "./elements/MemberCard";
import { FaPlusCircle } from "react-icons/fa";
import './style.scss';
import { UserActivity } from "../../../api/header.js";
import { withRouter } from 'react-router-dom';
import { setLoader} from "../../../actions/Application";

class Memberlist extends Component{

    componentWillMount(){
        this.props.setLoader({ loader: {open: true, loadingText:this.props.translate('loadingMember')}});
    }

    componentWillReceiveProps(newProps){
        if(newProps.members && newProps.members.constructor === Array){
            this.props.setLoader({ loader: {open: false} });       
        }

        if(this.props.members !== newProps.members && newProps.members.length > 0){
            if (this.props.loginUser.userActivityFlag == true) {
                const { location } = this.props;
                const toUrl = location.pathname;
                const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
                this.trackUserActivity('Success', (`Successfully navigated from ${fromUrl} to ${toUrl}`));
            }
        }
    }

    getFullName(member){   
        return [member.firstName || "", member.middleName || "", member.lastName || ""].join(" ");
    }

    trackUserActivity(pageActivity,remarks) {
        const {location} = this.props;
        const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
        const toUrlPath = location.pathname.split('/customer/')[1] || '';
        let fromUrlPath = fromUrl ? fromUrl.split('/customer/')[1]:'';
        const pathNames = {
            "manage-daily-page": "Manage Daily Work",
            "equipment": "My Equipments",
            "location-information": "Location Information",
            "alert-setting": "Alert Setting",
            "reports": "Manage Reports",
            "service-history": "Equipment Service History",
            "area-measurement": "Area Measurement",
            "members" : "My Members",
            "member_management": "Member Management",
            "my-account": "My Account"
            // Add other mappings here as needed
        };
        const convertToTitleCase = (str) => {
            if(!str){
                return '';
            }

            return str
                .replace(/-/g, ' ') // Replace hyphens with spaces
                .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
        };

        let defaultFromPage = fromUrl === '/customer' ? 'Login' : '';
        let defaultToPage = location.pathname === '/customer' ? 'Login' : '';
        let fromUrlName = pathNames[fromUrlPath] || convertToTitleCase(fromUrlPath) || defaultFromPage;
        let toUrlName = pathNames[toUrlPath] || convertToTitleCase(toUrlPath) || defaultToPage;

        let userActivityData = {
            userID: this.props.loginUser.id,
            userName: this.props.loginUser.firstName,
            sourceType: 'web',
            fromPage: fromUrlName,
            toPage: toUrlName,
            pageActivity: pageActivity,
            remarks: remarks
        }
        UserActivity(userActivityData)
            .then(result => {
                if (result.statusCode == 200) {
                    this.setState({fromPage:fromUrl});
                }
            })
            .catch(error => {
                console.error('Error in user activity API', error);
            })
    }

    render(){
        let { members, loginUser,translate } = this.props;
        return(
            <Fragment>
                    {loginUser && loginUser.ownerFlag && <Row className='no-gutters align-items-center'>
                        <Col className='text-right mb-20'>
                            <Link to={'/customer/members/add'} className='btn btn-primary float-right'><FaPlusCircle size='1em' />{translate('addNewMember')}</Link>
                        </Col>    
                    </Row>}

                    <Row className="members">    
                        {typeof members === 'object' && members.length > 0 && members.map((member, i) => <MemberCard key={i} loginUser={loginUser} member={member} getFullName={this.getFullName} translate={translate} />)}
                        {typeof members === 'object' && members.length === 0 && <Col><Alert color="primary">{translate('noMemberFound')} </Alert></Col> }
                    </Row>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return ({
        loginUser: state.profile || {ownerFlag: false}
    });
}

const mapDispatchToProps = {
    setLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Memberlist));
