import React,  { Component } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import ButtonField from '../../shared/ButtonField';
import InputField from '../../shared/InputField';
import MapComponent from '../../shared/MapComponent';
import {FaAngleDown} from 'react-icons/fa'
import { getEquipmentLocation } from "../../../api/locationInformation";
import { getBoundData } from '../../../actions/LocationInformation';
import { setLoader } from "../../../actions/Application";
import active from '../../../assets/images/map/Active.png';
import offline from '../../../assets/images/map/Offline.png';
import activeError from '../../../assets/images/map/ActiveError.png';
import offlineError from '../../../assets/images/map/OfflineError.png';
import { translate } from '../../../lang';
import { UserActivity } from "../../../api/header.js";
import { withRouter } from 'react-router-dom';
/*global google*/

class LocationInformationComponent extends Component{

	constructor(props){
		super(props);
		this.child = React.createRef();
		this.state = {
			numberPlateOption: [],
			optionType: 0,
			bound: null,
			center: {lat: 23.63936, lng: 68.14712},
			markers: [],
			vertices: null,
			mapTypeId:'roadmap',
			machineSaleId: [],
			tractorDetail: [],
			showHideDropDown: 'hidden',
			fromPage:'',
            errorAPI: false,
		}

		this.mapBound = this.mapBound.bind(this)
	}

	getNumberPlate() {
      let equipmentData = JSON.parse(localStorage.getItem('equipmentData'));
      if(equipmentData){
			// Number Plate Option
			let obj = equipmentData.equipmentData
			let result = Object.values(obj);
			
			result = result.map((item, index) => {
			return 	[item.machineSaleId, item.numberPlate];
			})

			let numberPlate = equipmentData.equipmentData.map((item, index) => {
				return (
					<React.Fragment  key={index}>
						<div className="checkbox-group">
							<label htmlFor={item.machineSaleId}>{item.numberPlate}
							<input key={index} type="checkbox" name={item.machineSaleId} id={item.machineSaleId} className='number_plates' label={item.numberPlate} value={item.numberPlate !== null && item.numberPlate} onChange={this.selectChange.bind(this)} /> 
							<span className='checkmark'></span>							
							</label>
						</div>
					</React.Fragment>
				);
			});
            return [numberPlate];
        }
        return [];
        
	}

	componentWillReceiveProps(newProps) {
        if (this.props.apiError !== newProps.apiError) {
            if (newProps.apiError !== false) {
                this.setState({
                    errorAPI: true,
                    showLoader: false
                })
                if (this.props.profile.userActivityFlag == true) {
                    const { location } = this.props;
                    const toUrl = location.pathname;
                    const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
                    this.trackUserActivity('Failed',(`Failed navigation from ${fromUrl} to ${toUrl}`));
                }
            }
        }
    }

	componentDidMount () {
		let data = JSON.parse(localStorage.getItem('equipmentData')), { machineSaleId }= this.state;

		if(data && data.equipmentData){
			machineSaleId = data.equipmentData.map(eq => {
				//document.getElementById(eq.machineSaleId).checked = true;
				return eq.machineSaleId;
			})
		}

		this.setState({
			numberPlateOption : this.getNumberPlate(),
			machineSaleId
		}, () => {
			if(machineSaleId.length){
				this.viewLocation(machineSaleId);
				//this.setState({numberPlateOption : this.getNumberPlate()})
			}
		})
	}

	selectChange(selected, type) {
		const { name }= selected.target, {machineSaleId} = this.state;
		let mc = +name;

		if (selected.target.checked) {
		  if(machineSaleId.indexOf(mc) < 0) machineSaleId.push(mc);
		} else {
		  if(machineSaleId.indexOf(mc) >= 0) machineSaleId.splice(machineSaleId.indexOf(mc), 1);
		}

		this.setState({ machineSaleId });
	  }

	viewLocation (data) {
		
		if(this.state.machineSaleId.length > 0) {

			data = {machineSaleIdList: this.state.machineSaleId};
			this.props.setLoader({loader:{open:true}});

			getEquipmentLocation(data)
			.then(result => {
				let data = result.data;
				let mapData = this.getMarkerPosition(data);
				this.props.getBoundData(mapData.bound);
				this.props.setLoader({loader:{open:false}});

				this.setState({
					tractorDetail: data,
					markers: mapData.markers,
					bound: mapData.bound,
					showHideDropDown:"hidden"
				})
				setTimeout(() => {
					this.manipulateState();
				}, 0);
			})
			.catch(error => {
				this.props.setLoader({loader:{open:false}});
			})
		}
	}


	getMarkerPosition (data) {
		let markers = [];
		let equipmentData = JSON.parse(localStorage.getItem('equipmentData'));
		data.forEach((val,index)=>{
			var result = equipmentData.equipmentData.filter(obj => {
			if(obj.machineSaleId == val.machineSaleId) {
					obj.index = index
					return obj;
				}
			})

			
			if(result.length > 0){
				let icon = '';
				if(data[result[0].index].errorFlag && data[result[0].index].runningStatus === "Offline"){
					icon = 'offlineError'+data[result[0].index].status;
				}else if (data[result[0].index].errorFlag && data[result[0].index].runningStatus === "Active"){
					icon = 'activeError'+data[result[0].index].status;
				}else if (!data[result[0].index].errorFlag && data[result[0].index].runningStatus === "Offline"){
					icon = 'offline'+data[result[0].index].status;
				}else if (!data[result[0].index].errorFlag && data[result[0].index].runningStatus === "Active"){
					icon = 'active'+data[result[0].index].status;
				}else if (!data[result[0].index].errorFlag && data[result[0].index].runningStatus === null){
					icon = 'offline'+data[result[0].index].status;
				} else if (data[result[0].index].errorFlag && data[result[0].index].runningStatus === null) {
					icon = 'offline' + data[result[0].index].status;
				}
				
				markers.push({
					'name': result[0].index,
					'icon': icon,
					'machineSaleId': data[result[0].index].machineSaleId,
					'runningStatus': data[result[0].index].runningStatus,
					'terminalId': data[result[0].index].terminalId,
					'lat': parseFloat(data[result[0].index].latitude), 'lng': parseFloat(data[result[0].index].longitude),
					'address': result[0].address,
					'logo': result[0].logo,
					'model': result[0].model,
					'machineNum': result[0].machineNum,
					'equipmentType': (result && result[0].equipmentType) ? result[0].equipmentType.toUpperCase() : " ",
					'numberPlate': result[0].status == 'TE' ? result[0].numberPlate : "",
					'operatingStatus': result.lastUpdateTime,
					'hourmeter': result.hourmeter
				});
			}

		})

		var bound = this.mapBound(markers);
		return {markers, bound};
	}

	mapBound (markers, bounds) {	
		//location bounds
			bounds = new google.maps.LatLngBounds();	
			if (markers && markers.length ) {
				if(this.props.profile.userActivityFlag == true){
					const {location} = this.props;
					const toUrl = location.pathname; 
					const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
					this.trackUserActivity('Success',(`Successfully navigated from ${fromUrl} to ${toUrl}`));
				}}
			if(markers && markers.length){
				let processed = 0;
				for (var i = 0; i < markers.length; i++) {
					if(markers[i].lat && markers[i].lng && !isNaN(markers[i].lat) && !isNaN(markers[i].lng)){
						var mapLatLng = new google.maps.LatLng(markers[i].lat, markers[i].lng)
						bounds.extend(mapLatLng);
						processed += 1;
					}
				}

				if(processed===0){
					let mapLatLngj = new google.maps.LatLng(23.63936, 68.14712);
					let mapLatLngj2 = new google.maps.LatLng(28.20453, 97.34466);

					bounds.extend(mapLatLngj);
					bounds.extend(mapLatLngj2);
				}
			}else{

				let mapLatLngj = new google.maps.LatLng(23.63936, 68.14712);
				let mapLatLngj2 = new google.maps.LatLng(28.20453, 97.34466);

				bounds.extend(mapLatLngj);
				bounds.extend(mapLatLngj2);
			}

			return bounds;
	  }

	selectAll = () => {
		let machineSaleId = [];
		let fields = document.getElementsByClassName('number_plates');
		for (let i=0; i<fields.length; i++){
			fields[i].checked = true;
			machineSaleId.push(+fields[i].getAttribute("name"));
		}
		this.setState({ machineSaleId });
	}

	unSelectAll (fields) {
		fields = document.getElementsByClassName('number_plates');
		for (let i=0; i<fields.length; i++){
			fields[i].checked = false;
			this.setState({ machineSaleId: []})
		}
	}

	showDropdown = () => {
		if(this.state.showHideDropDown === 'hidden'){
			this.setState({showHideDropDown:'show'});
		}
		else{
			this.setState({showHideDropDown:'hidden'})
		}	
	}

	manipulateState(){
		this.setState({ showHideDropDown: "hidden" });
	}

	searchEquipment = (field, list, searchText) => {
		field = document.getElementById('searchNumberPlate');
		list = document.getElementsByClassName('number_plates');
		searchText = field.value.toUpperCase();
		for(let i=0; i<list.length; i++){
			if(list[i].value.toUpperCase().indexOf(searchText) > -1){
				list[i].parentNode.style.display = '';
			}
			else list[i].parentNode.style.display = 'none';
		}
	}

	trackUserActivity(pageActivity,remarks) {
        const {location} = this.props;
        const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
        const toUrlPath = location.pathname.split('/customer/')[1] || '';
        let fromUrlPath = fromUrl ? fromUrl.split('/customer/')[1]:'';
        const pathNames = {
            "manage-daily-page": "Manage Daily Work",
            "equipment": "My Equipments",
            "location-information": "Location Information",
            "alert-setting": "Alert Setting",
            "reports": "Manage Reports",
            "service-history": "Equipment Service History",
            "area-measurement": "Area Measurement",
            "members" : "My Members",
            "member_management": "Member Management",
            "my-account": "My Account"
            // Add other mappings here as needed
        };
        const convertToTitleCase = (str) => {
            if(!str){
                return '';
            }
            
            return str
                .replace(/-/g, ' ') // Replace hyphens with spaces
                .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
        };

        let defaultFromPage = fromUrl === '/customer' ? 'Login' : '';
        let defaultToPage = location.pathname === '/customer' ? 'Login' : '';
        let fromUrlName = pathNames[fromUrlPath] || convertToTitleCase(fromUrlPath) || defaultFromPage;
        let toUrlName = pathNames[toUrlPath] || convertToTitleCase(toUrlPath) || defaultToPage;

        let userActivityData = {
            userID: this.props.profile.id,
            userName: this.props.profile.firstName,
            sourceType: 'web',
            fromPage: fromUrlName,
            toPage: toUrlName,
            pageActivity: pageActivity,
            remarks: remarks
        }
        UserActivity(userActivityData)
            .then(result => {
                if (result.statusCode == 200) {
                    this.setState({fromPage:fromUrl});
                }
            })
            .catch(error => {
                console.error('Error in user activity API', error);
            })
    }

	render(){
		let data = JSON.parse(localStorage.getItem('equipmentData'));
		return (
			<section className="c-location-information">
				<section className="conatiner">
					<Row>
						<Col xs="12">
							<h6>{translate('selectEquipment')} </h6>
						</Col>
					</Row>
					<Row>
						<Col xs="7">
							<div className="multiselect"> 
								<p onClick={this.showDropdown}>{this.state.machineSaleId.length > 0 ? `${this.state.machineSaleId.length} selected`: '0 selected'} <FaAngleDown/></p>
								<section className={'toggle '+ (this.state.showHideDropDown)}>
									<div className="cta row">
										<Col xs="12" md={12} lg={6}>
											<InputField 
												type="text"
												name="searchNumberPlate"
												id="searchNumberPlate"
												handleChange = {this.searchEquipment.bind(this)}
												placeholder= {translate("enterModelMachineNumberPlate")}
											/>
										</Col>
										<Col xs="12" md={6} sm={6} lg={3}>
											<ButtonField
												type="button"
												name = "selectAll"
												id="selectAll"
												className = "btn-primary btn-select-all pr-2 pl-2"
												buttonText = {translate('selectAll')}											clickEvent = {true}
												handleClick = {this.selectAll.bind(this)}
											/>
										</Col>
										<Col xs="12" md={6} sm={6} lg={3}>
											<ButtonField
												type="button"
												name = "unSelectAll"
												id="unSelectAll"
												className = "btn-primary btn-unselect-all pl-2 pr-2"
												buttonText = {translate('unSelectAll')}	
												clickEvent = {true}
												handleClick = {this.unSelectAll.bind(this)}
											/>
										</Col>
									</div>
									<FormGroup className="multiselect-dropdown">
										{/* {this.state.numberPlateOption} */}

										{data && data.equipmentData.map((item, index) => {
									
										return (
											<React.Fragment  key={index}>
												<div className="checkbox-group">
													 
													
                                                    <label htmlFor={item.machineSaleId}>{item.status == 'TE'  ? item.model+'/'+item.machineNum + '/'+ ((item.numberPlate ? item.numberPlate.toUpperCase() : '')) : item.model+'/'+item.machineNum}
 													<input key={index} type="checkbox" name={item.machineSaleId} id={item.machineSaleId} className='number_plates' label={(item.numberPlate ? item.numberPlate.toUpperCase(): " ")} value={item.status=='TE' ? item.model+'/'+item.machineNum+ '/'+((item.numberPlate ? item.numberPlate.toUpperCase() : ''))  : item.model+'/'+item.machineNum} onChange={this.selectChange.bind(this)} checked={this.state.machineSaleId.indexOf(+item.machineSaleId) >= 0 ? true : false} /> 
 													<span className={`checkmark`}></span>
													</label>
												</div>
											</React.Fragment>
										)
									})}


									</FormGroup>
								</section>
							</div>
						</Col>
						<Col xs="5">
						<ButtonField
							type="button"
							name = "viewLocation"
							id="viewLocation"
							className = {this.state.machineSaleId.length > 0 ? 'btn-primary btn-view-location' : 'btn-danger btn-view-location disabled'}
							buttonText = {translate('viewLocation')}
							clickEvent = {true}
							handleClick = {this.viewLocation.bind(this)}
						/>
						</Col>
					</Row>
					<Row>
						<Col>
						<MapComponent 
							t="location"
							mapTypeId={this.state.mapTypeId}
							manipulateState={this.manipulateState.bind(this)}
							ref={this.child}
							bound={this.state.bound}
							markers={this.state.markers} 
							drawingMode={this.state.drawingMode}
							center={this.state.center} 
							drawingControl={this.state.drawingControl} 
							coords={this.state.geofenceCordinate}
							/>
						</Col>
					</Row>
					<Row>
						<Col className="tractor-status">
							<ul>
								<li><img src={active} /><span>{translate('activeTractor')}</span></li>
								<li><img src={offline} /><span>{translate('offlineTractor')}</span></li>
								<li><img src={activeError} /><span>{translate('activeErrorTractor')}</span></li>
								<li><img src={offlineError} /><span>{translate('offlineErrorTractor')}</span></li>
							</ul>
						</Col>
					</Row>
				</section>
			</section>
		)
	}
}
const mapStatToProps = state => {
	return({
		bound: state.locationInformation.boundData || undefined,
		profile: state.profile,
		apiError : state.equipment.FETCH_DATA_FAIL || false,
	})
}
const mapDispatchToProps = { getBoundData, setLoader }
export default connect(mapStatToProps, mapDispatchToProps)(withRouter(LocationInformationComponent));
