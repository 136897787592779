import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { saveFieldArea } from "../../../api/areaMeasurement";
import SearchReportFormAreaMeasurement from '../../../forms/SearchReportFormAreaMeasurement';
import { setSarObj } from "../../../actions/AlertSetting";
import { setToastr, setLoader } from "../../../actions/Application";
import MapComponent from '../../shared/MapComponent';
import moment from "moment";
import { translate } from '../../../lang';
import SelectField from '../../shared/SelectField';
import { FaSearchLocation } from 'react-icons/fa';
import { GeoCodeKey } from "../../../constants/Constants";
import './AreaMeasurementComponent.scss';
import Geocode from "react-geocode";
import { UserActivity } from "../../../api/header.js";

Geocode.setApiKey(GeoCodeKey);
//Geocode.setLanguage("en");
//Geocode.setRegion("es");

/*global google*/
class AreaMeasurementComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			numberPlateOption: {},
			data: {},
			fromDate: null,
			toDate: null,
			getMachineData: false,
			markers: [],
			markrs: [],
			drawingMode: null,
			drawingControl: true,
			center: { lat: 23.63936, lng: 68.14712 },
			vertices: null,
			showLoader: false,
			radius: 0,
			group: null,
			area: 0,
			roundOffArea: 0,
			areaOptions: [],
			address: [],
			hiddenArea:false,
			fromPage:'',
			errorAPI: false,
		}
		this.handleAreaChange = this.handleAreaChange.bind(this);


	}

	handleAreaChange(val) {
		//this.setState({area: 0});
		this.setState({ areaOptions: []});
		this.setState({ area: val, areaOptions: this.getAreaOptions(), roundOffArea: val.toFixed(4) });
	}

	handleChange(event) {
		let area = this.state.area;
		let unit = event.target.value;
		if (unit == 'hectare') {
			area = area / 10000;
			this.setState({ area: area, roundOffArea: area.toFixed(4) });
		}
		else if (unit == 'sqmt') {
			area = area * 10000;
			this.setState({ area: area, roundOffArea: area.toFixed(4) });
		}
	}

	handleInput = event => {
		this.setState({ address: event.target.value });
	};

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({ showLoader: false })
		}
		if (nextProps.sarData && nextProps.sarData.lat && nextProps.sarData.lng) {
			let sar = nextProps.sarData;
			this.setState({
				markrs: (sar && sar.lat && sar.lng) ? [{ lat: parseFloat(sar.lat), lng: parseFloat(sar.lng) }] : this.state.markrs
			})
		} else {
			this.setState({
				markrs: []
			});
		}
	}

	selectChange(type, data) {
		if (data === undefined) {
			data = type.target.value;
			type = type.target.name;
		}
		if (!data) return false;
		this.props.setLoader({ loader: { open: true } });

		if (data === "null") {
			this.setState({ getMachineData: false });
			this.props.setLoader({ loader: { open: false } });
		} else {
			let equipmentsData = JSON.parse(localStorage.getItem('equipmentData')).equipmentData;
			let equipment = equipmentsData.filter(equipment => equipment.machineSaleId == data);
			this.setState({ group: equipment[0].status });
			if (equipment[0].sar && equipment[0].sar.latitude && equipment[0].sar.longitude) {
				this.props.setSarObj({
					lat: equipment[0].sar.latitude,
					lng: equipment[0].sar.longitude
				});
			}
			console.log(equipment[0].contractExpiry, "equipment[0].contractExpiry", new Date())
			this.setState({
				getMachineData: true,
				data: {
					...this.state.data, [type]: data
				},
				showLoader: false,
				hiddenArea: moment(new Date()).isAfter(equipment[0].contractExpiry)
			})
			this.props.setLoader({ loader: { open: false } });
		}
	}

	getNumberPlate() {
		let equipmentData = JSON.parse(localStorage.getItem('equipmentData'));
		if (equipmentData) {
			//Number Plate Option
			let numberPlate = [];
			//Model Machine Number
			let modelMachine = equipmentData.equipmentData.map(function (item, index) {
				if (item.status == 'CH')
					return <option key={index} value={item.machineSaleId}> {item.model}/{item.machineNum} </option>;
			});
			return [numberPlate, modelMachine, equipmentData];
		}
		return [];
	}

	getAreaOptions() {
		let areaOptions = [];
		let areaOptionHectare;
		let areaOptionSquareMt = <option key='sq' value='sqmt'> {translate('sqmt')}</option>;
		areaOptionHectare = <option key='hectare' value='hectare'> {translate('hectare')} </option>;
		areaOptions.push(areaOptionSquareMt, areaOptionHectare);
		return areaOptions;
	}

	search(event) {
		this.setState({
			markrs: [{ lat: 29.4727, lng: 77.7085 }]
		})
	}

	saveFieldArea(event) {
		let machineSaleId = this.state.data.machineSaleId;
		let area = this.state.area;
		let data = { machineSaleId, area: area }

		saveFieldArea(data)
			.then(result => {
				this.props.setToastr({ toastr: { message: translate('areaUpdated'), type: "success" } });
				this.props.setLoader({ loader: { open: false } });
			})
			.catch(error => {
				this.props.setToastr({ toastr: { message: translate('errorOccured'), type: "error" } });
				this.props.setLoader({ loader: { open: false } });
			})
	}

	componentDidMount() {
		let { machineSaleId } = this.props.match.params;
		if (machineSaleId) {
			let f = document.getElementById("u2");
			if (f && f.dataset['url'] && f.dataset['url'] === '/customer/equipment/equipmentDetail/:machineSaleId') {
				f.dataset['url'] = `/customer/equipment/equipmentDetail/${machineSaleId}`;
			}
		}
		this.setState({
			numberPlateOption: this.getNumberPlate(),
		})
		if(this.props.profile.userActivityFlag == true){
			const {location} = this.props;
			const toUrl = location.pathname; 
			const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
			this.trackUserActivity('Success',(`Successfully navigated from ${fromUrl} to ${toUrl}`));
		}	
	}

	getCoordinates = (vertices) => {
		let coords = [], xy, lat, lng;
		for (let i = 0; i < vertices.getLength(); i++) {
			xy = vertices.getAt(i);
			lat = xy.lat();
			lng = xy.lng();
			coords.push({ 'lat': lat, 'lng': lng });
		}
		return coords;
	}
	getReactangleCoordinates = (data) => {
		let coords = [], lat, lng;
		var dataKeys = Object.keys(data);

		for (let i = 0; i < dataKeys.length; i++) {
			lat = data[i].lat();
			lng = data[i].lng();
			coords.push({ 'lat': lat, 'lng': lng });
		}
		return coords;
	}
	catchVertices(data, polygon, center, radius = null) {
		if (polygon.type === 'polygon') {
			this.setState({
				geofenceCordinate: this.getCoordinates(data),
				center: this.getCoordinates(data)[0]
			})
		}
		else if (polygon.type === 'rectangle') {
			this.setState({
				geofenceCordinate: this.getReactangleCoordinates(data),
				center: center
			})
		}
		else if (polygon.type === 'circle') {
			this.setState({
				geofenceCordinate: data,
				center: center,
				radius
			})
		}
	}

	searchLocation() {
		let { address } = this.state
		Geocode.fromAddress(address).then(
			(response) => {
				const { lat, lng } = response.results[0].geometry.location;
				this.setState({
					markrs: [{ lat: lat, lng: lng }]
				})
			},
			(error) => {
				console.error(error);
			}
		);
	}

	trackUserActivity(pageActivity,remarks) {
        const {location} = this.props;
        const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
        const toUrlPath = location.pathname.split('/customer/')[1] || '';
        let fromUrlPath = fromUrl ? fromUrl.split('/customer/')[1]:'';
        const pathNames = {
            "manage-daily-page": "Manage Daily Work",
            "equipment": "My Equipments",
            "location-information": "Location Information",
            "alert-setting": "Alert Setting",
            "reports": "Manage Reports",
            "service-history": "Equipment Service History",
            "area-measurement": "Area Measurement",
            "members" : "My Members",
            "member_management": "Member Management",
            "my-account": "My Account"
            // Add other mappings here as needed
        };
        const convertToTitleCase = (str) => {
            if(!str){
                return '';
            }
            
            return str
                .replace(/-/g, ' ') // Replace hyphens with spaces
                .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
        };

        let defaultFromPage = fromUrl === '/customer' ? 'Login' : '';
        let defaultToPage = location.pathname === '/customer' ? 'Login' : '';
        let fromUrlName = pathNames[fromUrlPath] || convertToTitleCase(fromUrlPath) || defaultFromPage;
        let toUrlName = pathNames[toUrlPath] || convertToTitleCase(toUrlPath) || defaultToPage;

        let userActivityData = {
            userID: this.props.profile.id,
            userName: this.props.profile.firstName,
            sourceType: 'web',
            fromPage: fromUrlName,
            toPage: toUrlName,
            pageActivity: pageActivity,
            remarks: remarks
        }
        UserActivity(userActivityData)
            .then(result => {
                if (result.statusCode == 200) {
                    this.setState({fromPage:fromUrl});
                }
            })
            .catch(error => {
                console.error('Error in user activity API', error);
            })
    }


	render() {
		const machineId = true;
		const showArea = true;
		return (
			<section className="c-alert-setting">
				<section className="conatiner">
					<Row className="c-alert-setting-search-equiment">
						<Col xs="12" lg="12">
							<SearchReportFormAreaMeasurement
								numberPlateOption={this.getNumberPlate()}
								handleChange={this.selectChange.bind(this)}
								fromDate={this.state.fromDate}
								toDate={this.state.toDate}
								machineSaleId={machineId}
								translate={translate}
							/>
						</Col>
						<Col xs="12" lg="2" style={{ paddingBottom: "15px" }}>
							<label style={{ paddingBottom: "15px" }} for="exampleSelect">{translate('searchAddress')}</label>

						</Col>
						<Col xs="4" style={{ paddingLeft: "49px" }}>
							<input onChange={this.handleInput} style={{ width: "271px", height: "calc(2.25rem + 2px)", border: "1px solid #ced4da",padding:"15px"}} placeholder={translate('Search_for_address')} />
							<button style={{
								position: "absolute",
								border: "0",
								display: "inline-flex",
								top: "7px",
								right: "31px",
								fontSize: "1.5rem",
								backgroundColor: "white",
								padding: "0",

							}} className="calLogo" onClick={() => this.searchLocation()}><FaSearchLocation color={'grey'} height={'0.8em'} /></button>
						</Col>
						{/*<Col xs="7">
						<ButtonField
							type="button"
							name = "search"
							id="search"
							className = {this.state.address.length > 0 ? 'btn-danger btn-view-location' : 'btn-danger btn-view-location disabled'}
							buttonText = {translate('searchArea')}
							clickEvent = {true}
							handleClick = {this.search.bind(this)}
						/>
						</Col>*/}
						{this.state.area != 0 && !this.state.hiddenArea &&
							<Col xs="12" lg="12" >
								<Row style={{ paddingBottom: "15px" }}>
									<Col xs="12" lg="2">
										<Label for="exampleSelect" className="calculateArea" >{translate('calculateArea')}</Label>

									</Col>
									<Col xs="3" lg="3" style={{ paddingLeft: "42px" }}>
										<span className='highlighted' style={{ paddingRight: "12px" }}>{this.state.roundOffArea}</span>


										<SelectField
											divClass="selectwrapper "
											handleChange={this.handleChange.bind(this)}
											options={this.state.areaOptions}
											type="select"
											name="areaUnit"
											className=''
											defaultOption={''}
										/>

									</Col>
									{/*<Col xs="4" lg="4" >
										<ButtonField
											type="button"
											name="saveFieldArea"
											id="saveFieldArea"
											className={this.state.area != 0 ? 'btn-danger btn-view-location' : 'btn-danger btn-view-location disabled'}
											buttonText={translate('saveArea')}
											clickEvent={true}
											handleClick={this.saveFieldArea.bind(this)}
										/>
						</Col>*/}
								</Row>
							</Col>
						}

						{this.state.getMachineData && !this.state.hiddenArea ?
							<div className="setting">

								<Col xs="12" className="geofencing">
									<Col xs="12" >
										<h3 className="header-text xs-hidden">{translate('areaText')}</h3>
									</Col>
									<MapComponent
										showArea={showArea}
										t='alert'
										handleVertices={this.catchVertices.bind(this)}
										markers={this.state.markers}
										markrs={this.state.markrs}
										handleArea={this.handleAreaChange}
										drawingMode={this.state.drawingMode} center={this.state.center} radius={this.state.radius} drawingControl={this.state.drawingControl} coords={this.state.geofenceCordinate} />
								</Col>

							</div> : null
						}
						{this.state.hiddenArea && <div>
							<Col xs="12" className="errorMessage" align='center'> {translate('This_Page_cannot_be_displayed_as_contract_has_expired_for_selected_machine_Kindly_contact_your_service_provider')}.
							</Col></div>}
					</Row>
				</section>
			</section>
		)
	}
}
const mapStatToProps = state => {
	return ({
		sarData: state.alertSetting.displayAlertSarData || undefined,
		profile: state.profile,
		apiError : state.equipment.FETCH_DATA_FAIL || false,
	})
}
const mapDispatchToProps = { setToastr, setLoader, setSarObj }
export default connect(mapStatToProps, mapDispatchToProps)(withRouter(AreaMeasurementComponent))
