import React, { Component, Fragment } from 'react';
import { Row, Col, FormGroup } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { renderSelectField } from "../../shared/renderField";
import { required } from "../../shared/Validation";
import { connect } from "react-redux";
import { saveMemeberFunction } from "../../../api/members";
import { setToastr, setLoader } from "../../../actions/Application";
import { withRouter } from "react-router-dom";
import { getMemberList } from "../../../actions/Member";
import { UserActivity } from "../../../api/header.js";

let form = "memberRole";
class MemberManagementComponent extends Component {
    constructor(props) {
        super(props);

        /** define component state */
        this.state = {
            selectedMember: null,
            permissionList: ['My Equipments']
        }
        this.onMemberSelect = this.onMemberSelect.bind(this)
        this.updateMemberRole = this.updateMemberRole.bind(this)
    }
    componentWillMount() {
        this.props.setLoader({ loader: { open: true, loadingText: this.props.translate('loadingMember') } });

    }
    componentWillReceiveProps(newProps) {
        if (newProps.memberList && newProps.memberList.constructor === Array) {
            this.props.setLoader({ loader: { open: false } });
        }
        if (newProps.memberList && newProps.memberList.constructor === Array) {
            if (this.props.profile.userActivityFlag == true) {
                const { location } = this.props;
                const toUrl = location.pathname;
                const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
                this.trackUserActivity('Success', (`Successfully navigated from ${fromUrl} to ${toUrl}`));
            }
        }
    }

    updateMemberRole(value) {
        let { permissionList } = this.state;
        let functionList = [];
        permissionList.map(permission => {
            let newRole = {
                "memberId": value.memberName,
                "functionName": permission
            }
            functionList.push(newRole);
        })
        if (permissionList.length === 0) {
            let newRole = {
                "memberId": value.memberName,
                "functionName": null
            }
            functionList.push(newRole);
        }
        this.props.setLoader({ loader: { open: true } });
        saveMemeberFunction({ 'functions': functionList })
            .then(result => {
                setTimeout(() => {
                    this.props.setLoader({ loader: { open: false } });
                    this.props.setToastr({ toastr: { message: this.props.translate('newPermissionAddedSuccess'), type: "success" } });
                    this.props.getMemberList();
                }, 0);


            })
            .catch(error => {
                this.props.setLoader({ loader: { open: false } });
                this.props.setToastr({ toastr: { message: error || this.props.translate('encounteredProblem'), type: "error" } });
            });
    }
    onMemberSelect($event) {
        let { value } = $event.target;
        let { memberList } = this.props;
        let permissionList =[] ;

        let member = memberList.filter(member => member.id == value);

        member[0].functionList.map(mFunction => permissionList.push(mFunction.functionName))
        this.setState({ 'selectedMember': member, 'permissionList': permissionList })
    }

    clickCheckBox(event) {
        let { name } = event.target;
        let { permissionList } = this.state;

        if (permissionList && permissionList.includes(name)) {
            permissionList.splice(permissionList.indexOf(name), 1);
            
        } else {
            permissionList.push(name);
        }

        this.setState({ permissionList })
    }

    trackUserActivity(pageActivity,remarks) {
        const {location} = this.props;
        const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
        const toUrlPath = location.pathname.split('/customer/')[1] || '';
        let fromUrlPath = fromUrl ? fromUrl.split('/customer/')[1]:'';
        const pathNames = {
            "manage-daily-page": "Manage Daily Work",
            "equipment": "My Equipments",
            "location-information": "Location Information",
            "alert-setting": "Alert Setting",
            "reports": "Manage Reports",
            "service-history": "Equipment Service History",
            "area-measurement": "Area Measurement",
            "members" : "My Members",
            "member_management": "Member Management",
            "my-account": "My Account"
            // Add other mappings here as needed
        };
        const convertToTitleCase = (str) => {
            if(!str){
                return '';
            }

            return str
                .replace(/-/g, ' ') // Replace hyphens with spaces
                .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
        };

        let defaultFromPage = fromUrl === '/customer' ? 'Login' : '';
        let defaultToPage = location.pathname === '/customer' ? 'Login' : '';
        let fromUrlName = pathNames[fromUrlPath] || convertToTitleCase(fromUrlPath) || defaultFromPage;
        let toUrlName = pathNames[toUrlPath] || convertToTitleCase(toUrlPath) || defaultToPage;

        let userActivityData = {
            userID: this.props.profile.id,
            userName: this.props.profile.firstName,
            sourceType: 'web',
            fromPage: fromUrlName,
            toPage: toUrlName,
            pageActivity: pageActivity,
            remarks: remarks
        }
        UserActivity(userActivityData)
            .then(result => {
                if (result.statusCode == 200) {
                    this.setState({fromPage:fromUrl});
                }
            })
            .catch(error => {
                console.error('Error in user activity API', error);
            })
    }

    render() {
        let { memberList, handleSubmit, translate } = this.props;
        let { selectedMember, permissionList } = this.state;
        return (
            <Fragment>
                <form onSubmit={handleSubmit(this.updateMemberRole)} autoComplete='off' className='form-horizontal' id={form}>
                    <section className='member-personal-info '>
                        <Row className='no-gutters'>
                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    {translate('memberName')} <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="memberName" component={renderSelectField} validate={[required]} onChange={this.onMemberSelect}>
                                        <option value=""> {translate('selectMember')} </option>
                                        {memberList && memberList.map(member => <option key={member.id} value={member.id}>{member.fullName}</option>)}
                                    </Field>
                                </Col>
                            </Col>
                        </Row>
                    </section>
                    {selectedMember && <table className="tabledashboard table table-striped table-advance table-hover m-top-30" >
                        <thead>
                            <tr>

                                <th>{translate('permissions')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td> {translate('myEquipment')} </td>
                                <td>
                                    <FormGroup className="error-field">
                                        <div className="checkbox-group">
                                            <label>
                                                <input type="checkbox" disabled='true' onChange={this.clickCheckBox.bind(this)} name={'My Equipments'} id='Check' checked={permissionList.includes('My Equipments')} />
                                                <span className='checkmark'></span></label>
                                        </div>
                                    </FormGroup>
                                </td>

                            </tr>

                            <tr >
                                <td>{translate('manageDailyWork')}</td>
                                <td>
                                    <FormGroup className="error-field">
                                        <div className="checkbox-group">
                                            <label>
                                                <input type="checkbox" /* disabled = {readonly} */ onChange={this.clickCheckBox.bind(this)} name={'Manage Daily Work'} id='Check' checked={permissionList.includes('Manage Daily Work')} />
                                                <span className='checkmark'></span></label>
                                        </div>
                                    </FormGroup>
                                </td>

                            </tr>

                            <tr >
                                <td>{translate('locationInformation')}</td>
                                <td>
                                    <FormGroup className="error-field">
                                        <div className="checkbox-group">
                                            <label>
                                                <input type="checkbox" /* disabled = {readonly} */ onChange={this.clickCheckBox.bind(this)} name={'Location Information'} id='Check' checked={permissionList.includes('Location Information')} />
                                                <span className='checkmark'></span></label>
                                        </div>
                                    </FormGroup>
                                </td>

                            </tr>

                            <tr >
                                <td>{translate('alertSettings')}</td>
                                <td>
                                    <FormGroup className="error-field">
                                        <div className="checkbox-group">
                                            <label>
                                                <input type="checkbox" /* disabled = {readonly} */ onChange={this.clickCheckBox.bind(this)} name={'Alert Settings'} id='Check' checked={permissionList.includes('Alert Settings')} />
                                                <span className='checkmark'></span></label>
                                        </div>
                                    </FormGroup>
                                </td>

                            </tr>
                            <tr >
                                <td>{translate('manageReports')}</td>
                                <td>
                                    <FormGroup className="error-field">
                                        <div className="checkbox-group">
                                            <label>
                                                <input type="checkbox" /* disabled = {readonly} */ onChange={this.clickCheckBox.bind(this)} name={'Manage Reports'} id='Check' checked={permissionList.includes('Manage Reports')} />
                                                <span className='checkmark'></span></label>
                                        </div>
                                    </FormGroup>
                                </td>

                            </tr>
                            <tr >
                                <td>{translate('equipmentServiceHistory')}</td>
                                <td>
                                    <FormGroup className="error-field">
                                        <div className="checkbox-group">
                                            <label>
                                                <input type="checkbox" /* disabled = {readonly} */ onChange={this.clickCheckBox.bind(this)} name={'Equipment Service History'} id='Check' checked={permissionList.includes('Equipment Service History')} />
                                                <span className='checkmark'></span></label>
                                        </div>
                                    </FormGroup>
                                </td>

                            </tr>
                            <tr >
                                <td>{translate('areaMeasurement')}</td>
                                <td>
                                    <FormGroup className="error-field">
                                        <div className="checkbox-group">
                                            <label>
                                                <input type="checkbox" /* disabled = {readonly} */ onChange={this.clickCheckBox.bind(this)} name={'Area Measurement'} id='Check' checked={permissionList.includes('Area Measurement')} />
                                                <span className='checkmark'></span></label>
                                        </div>
                                    </FormGroup>
                                </td>

                            </tr>
                            <tr >
                                <td>{translate('myMember')}</td>
                                <td>
                                    <FormGroup className="error-field">
                                        <div className="checkbox-group">
                                            <label>
                                                <input type="checkbox" /* disabled = {readonly} */ onChange={this.clickCheckBox.bind(this)} name={'My Members'} id='Check' checked={permissionList.includes('My Members')} />
                                                <span className='checkmark'></span></label>
                                        </div>
                                    </FormGroup>
                                </td>

                            </tr>
                            <tr >
                                <td>{translate('memberManagement')}</td>
                                <td>
                                    <FormGroup className="error-field">
                                        <div className="checkbox-group">
                                            <label>
                                                <input type="checkbox" /* disabled = {readonly} */ onChange={this.clickCheckBox.bind(this)} name={'Member Management'} id='Check' checked={permissionList.includes('Member Management')} />
                                                <span className='checkmark'></span></label>
                                        </div>
                                    </FormGroup>
                                </td>

                            </tr>
                            <tr >
                                <td>{translate('myAccount')}</td>
                                <td>
                                    <FormGroup className="error-field">
                                        <div className="checkbox-group">
                                            <label>
                                                <input type="checkbox" /* disabled = {readonly} */ onChange={this.clickCheckBox.bind(this)} name={'My Account'} id='Check' checked={permissionList.includes('My Account')} />
                                                <span className='checkmark'></span></label>
                                        </div>
                                    </FormGroup>
                                </td>

                            </tr>



                        </tbody>
                    </table>

                    }
                    <div>
                        <Row className='no-gutters'>
                            <Col md='7' sm='7' xs='12' >
                                {selectedMember && <button type="submit" className="btn btn-primary float-right">
                                    {translate('save')}
                                </button>}
                            </Col>

                        </Row>
                    </div>

                </form>
            </Fragment>
        );
    }

}
const mapDispatchToProps = {
    setToastr, setLoader, getMemberList

}
const mapStateToProps = state => {
    return ({
        memberList: (state.members && state.members.memberFunctions) ? state.members.memberFunctions.allMemberList : [],
        profile: state.profile
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form })(withRouter(MemberManagementComponent)));

